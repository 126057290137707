import { getReadableImageUrl } from '@/lib/directus'
import Image from 'next/image'
import { useRef, useEffect, useState } from 'react'
import { sanitizeHTML } from '@/lib/utils'

interface BgRightTxtScrollData {
	image?: {
		alt: string
		image_file: {
			image: {
				id: string
				filename_disk: string
			}
		}
	}
	heading?: {
		title: string
		text: string
	}
	text?: {
		title: string
		text: string
	}
	content_collection?: {
		id: string
		site_content_collections_id: {
			collection: {
				item: {
					title: string
					text: string
				}
			}[]
		}
	}[]
}

export const fields = [
	'image.alt',
	'image.image_file.image.id',
	'image.image_file.image.filename_download',
	'content_collection.id',
	'content_collection.site_content_collections_id.collection.item:site_content.title',
	'content_collection.site_content_collections_id.collection.item:site_content.text',
	'heading.title',
	'heading.text',
	'text.text',
] as const

interface BgRightTxtScrollProps {
	data?: BgRightTxtScrollData
}

export default function BgRightTxtScroll({ data }: BgRightTxtScrollProps) {

	const scrollContainerRef = useRef<HTMLDivElement>(null)
	const [canScrollUp, setCanScrollUp] = useState(false)
	const [canScrollDown, setCanScrollDown] = useState(true)

	// Handle scroll state
	const handleScroll = () => {
		if (!scrollContainerRef.current) return

		const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current
		setCanScrollUp(scrollTop > 0)
		setCanScrollDown(scrollTop < scrollHeight - clientHeight - 10)
	}

	useEffect(() => {
		const scrollContainer = scrollContainerRef.current
		if (scrollContainer) {
			scrollContainer.addEventListener('scroll', handleScroll)
			handleScroll() // Check initial state

			return () => scrollContainer.removeEventListener('scroll', handleScroll)
		}
	}, [])

	const scrollContent = (direction: 'up' | 'down') => {
		if (!scrollContainerRef.current) return

		const scrollAmount = 200
		const currentScroll = scrollContainerRef.current.scrollTop
		scrollContainerRef.current.scrollTo({
			top:
				direction === 'up'
					? currentScroll - scrollAmount
					: currentScroll + scrollAmount,
			behavior: 'smooth',
		})
	}

	return (
		<>
			<section className='BgRightTxtText relative w-full bg-white py-20'>
				<div className='container relative mx-auto'>
					<div className='grid grid-cols-1 lg:grid-cols-12 gap-2 items-center'>
						<div className='lg:col-span-5'>
							<h2
								className='font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-4 md:mb-0 text-left'
								dangerouslySetInnerHTML={sanitizeHTML(
									data?.heading?.title || ''
								)}
							/>
						</div>
						<div className='lg:col-span-7'>
							<div
								className='text-base font-light font-aileron leading-[27px] tracking-tight text-justify'
								dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
							/>
						</div>
					</div>
				</div>
			</section>
			<section className='BgRightTxtScroll relative w-full overflow-hidden bg-[#fbfaf7]'>
				<div className='relative w-full h-full min-h-[767px] flex flex-col lg:flex-row'>
					{/* Background Image */}
					<div className='lg:absolute relative top-0 left-0 w-full h-full min-h-[300px] lg:min-h-0'>
						<Image
							src={
								getReadableImageUrl(
									data?.image?.image_file?.image.id || '',
									data?.image?.image_file?.image.filename_download || ''
								) || ''
							}
							alt={data?.image?.alt || ''}
							fill
							className='object-cover object-left lg:object-center'
							priority
						/>
					</div>

					{/* Content Container */}
					<div className='container relative mx-auto'>
						<div className='flex flex-col lg:flex-row min-h-[767px]'>
							<div className='w-full lg:w-1/2'></div>
							{/* Scrollable Content */}
							<div className='w-full lg:w-1/2 px-4 lg:px-8 py-8 lg:py-20'>
								<div className='relative'>
									{/* Scroll Navigation */}
									<div
										className='absolute right-0 flex flex-col gap-4 -translate-x-8 top-1/2 -translate-y-1/2'
										style={{ right: '-50px' }}
									>
										<button
											onClick={() => scrollContent('up')}
											className={`rounded-full border border-black w-[48px] h-[48px] flex items-center justify-center transition-opacity ${
												!canScrollUp
													? 'opacity-25'
													: 'opacity-75 hover:opacity-100'
											}`}
											disabled={!canScrollUp}
										>
											<svg
												width='24'
												height='24'
												viewBox='0 0 41 40'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M13.0742 22.0508C12.8789 21.875 12.7813 21.6602 12.7813 21.4062C12.7813 21.1523 12.8789 20.9277 13.0742 20.7324L19.1387 14.668C19.2363 14.5703 19.3438 14.4971 19.4609 14.4482C19.5781 14.3994 19.6953 14.375 19.8125 14.375C19.9297 14.375 20.0469 14.3994 20.1641 14.4482C20.2813 14.4971 20.3887 14.5703 20.4863 14.668L26.5508 20.7324C26.7461 20.9277 26.8438 21.1523 26.8438 21.4063C26.8438 21.6602 26.7461 21.875 26.5508 22.0508C26.375 22.2461 26.1553 22.3438 25.8916 22.3438C25.6279 22.3438 25.4082 22.2461 25.2324 22.0508L19.8125 16.6309L14.3926 22.0508C14.2168 22.2461 13.9971 22.3438 13.7334 22.3438C13.4697 22.3438 13.25 22.2461 13.0742 22.0508Z'
													fill='#333333'
												/>
											</svg>
										</button>
										<button
											onClick={() => scrollContent('down')}
											className={`rounded-full border border-black w-[48px] h-[48px] flex items-center justify-center transition-opacity ${
												!canScrollDown
													? 'opacity-25'
													: 'opacity-75 hover:opacity-100'
											}`}
											disabled={!canScrollDown}
										>
											<svg
												width='24'
												height='24'
												viewBox='0 0 41 40'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
											>
												<path
													d='M13.0742 17.9492C12.8789 18.125 12.7812 18.3398 12.7812 18.5938C12.7812 18.8477 12.8789 19.0723 13.0742 19.2676L19.1387 25.332C19.2363 25.4297 19.3437 25.5029 19.4609 25.5518C19.5781 25.6006 19.6953 25.625 19.8125 25.625C19.9297 25.625 20.0469 25.6006 20.1641 25.5518C20.2812 25.5029 20.3887 25.4297 20.4863 25.332L26.5508 19.2676C26.7461 19.0723 26.8437 18.8477 26.8437 18.5938C26.8437 18.3398 26.7461 18.125 26.5508 17.9492C26.375 17.7539 26.1553 17.6563 25.8916 17.6563C25.6279 17.6563 25.4082 17.7539 25.2324 17.9492L19.8125 23.3691L14.3926 17.9492C14.2168 17.7539 13.9971 17.6563 13.7334 17.6563C13.4697 17.6563 13.25 17.7539 13.0742 17.9492Z'
													fill='#333333'
												/>
											</svg>
										</button>
									</div>

									{/* Scrollable Content */}
									<div
										ref={scrollContainerRef}
										className='max-h-[600px] overflow-y-auto pr-8 md:pr-16 space-y-9 scroll-smooth scrollbar-hide'
										style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
									>
										{data?.content_collection?.map(
											(collection, collectionIndex) => (
												<div
													key={collectionIndex}
													className='flex flex-col gap-4'
												>
													<div className='w-fit px-9 pt-4 pb-[17px] bg-black rounded-[100px] border border-[#b2b2b2]'>
														<h3 className='text-center text-white text-base font-semibold font-serenity capitalize leading-tight tracking-wider'>
															{collection?.site_content_collections_id
																?.collection?.[0]?.item?.title || ''}
														</h3>
													</div>
													<div
														className='prose text-[#212529] text-base font-light font-aileron leading-[27px] tracking-tight'
														dangerouslySetInnerHTML={sanitizeHTML(
															collection?.site_content_collections_id
																?.collection?.[1]?.item?.text || ''
														)}
													/>
												</div>
											)
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
