import Image from "next/image";
import Link from "@/components/ui/Link";
import { sanitizeHTML } from "@/lib/utils";
import { getReadableImageUrl } from "@/lib/directus";

interface AwardsAndRecognitionSectionData {
  images: {
    images_id: {
      alt?: string;
      image_file?: {
        image: {
          id: string;
          filename_download: string;
        };
      };
    };
  }[];
  text: {
    text?: string;
  }[];
  buttons: {
    menu_items_id: {
      href?: string;
    }[];
  };
}

export const fields = [
  "images.images_id.alt",
  "images.images_id.image_file.image.id",
  "images.images_id.image_file.image.filename_download",
  "text.text",
  "buttons.menu_items_id.href",
] as const;

export default function AwardsAndRecognitionSection({
  data,
}: {
  data: AwardsAndRecognitionSectionData;
}) {
  return (
    <section
      id="AwardsAndRecognitionSection"
      className="flex md:flex-row flex-col items-center md:items-start justify-center py-[81px] gap-8 md:gap-[5.5%] text-center max-w-[1080px] mx-auto text-sm"
    >
      {data?.images?.map((image, index) => (
        <div
          key={image?.images_id?.image_file?.image?.id}
          className="flex flex-col items-center w-[90%] md:w-[45%]"
        >
          {index === 1 ? (
            <Link href={data?.buttons?.menu_items_id?.[0]?.href || "#"}>
              <div>
                <Image
                  src={getReadableImageUrl(
                    image?.images_id?.image_file?.image?.id || "",
                    image?.images_id?.image_file?.image?.filename_download || ""
                  )}
                  alt={image?.images_id?.alt || ""}
                  width={350}
                  height={445}
                  className="mb-[5.82%]"
                  quality={95}
                  loading={"lazy"}
                />
                <div
                  className="max-w-[350px]"
                  dangerouslySetInnerHTML={sanitizeHTML(
                    data?.text?.[index]?.text || ""
                  )}
                />
              </div>
            </Link>
          ) : (
            <div>
              <Image
                src={getReadableImageUrl(
                  image?.images_id?.image_file?.image?.id || "",
                  image?.images_id?.image_file?.image?.filename_download || ""
                )}
                alt={image?.images_id?.alt || ""}
                width={350}
                height={445}
                className="mb-[5.82%]"
                quality={95}
                loading={"lazy"}
              />
              <div
                className="max-w-[350px]"
                dangerouslySetInnerHTML={sanitizeHTML(
                  data?.text?.[index]?.text || ""
                )}
              />
            </div>
          )}
        </div>
      ))}
    </section>
  );
}
